<template>
  <div
    v-if="flowsErrorMessage"
    class="h-full flex items-center justify-center"
  >
    <div>
      <p
        class="text-destructive-emphasis text-base"
      >
        {{ flowsErrorMessage }}
      </p>

      <button
        v-if="isErrorReloadButtonShown"
        type="button"
        class="bg-transparent w-full flex items-center justify-center h-8 p-2 border-[0.5px] border-solid border-default hover:border-brand-600 focus:border-brand-600 focus:bg-brand-50 rounded hover:bg-brand-50"
        @click="reloadFlows"
      >
        Reload Flows
      </button>
    </div>
  </div>

  <div
    v-if="flowsUrl"
    class="flex h-full w-full"
  >
    <GlobalLoading :is-loading="isLoadingIframe" />
    <iframe
      class="h-full w-full"
      :class="{ 'hidden': isLoadingIframe }"
      :src="flowsUrl"
      @load="onIframeLoad"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Api from '@/lib/api-wrapper';
import RequestUtils from '@/components/util/RequestUtils';
import Icon from '@/components/ui/Icon.vue';
import { FLOWS } from '@/constants/featureflags';
import GlobalLoading from '@/components/ui/GlobalLoading.vue';
import { isSharedBuilder } from '@/lib/plan-helper';

export default {
  name: 'FlowsList',
  components: { GlobalLoading, Icon },
  mixins: [
    RequestUtils,
  ],
  data() {
    return {
      flowsUrl: null,
      flowsErrorMessage: null,
      isErrorReloadButtonShown: false,
      isLoadingIframe: true,
    };
  },
  created() {
    if (isSharedBuilder() || !this.hasFeature(FLOWS)) {
      this.$router.push('/');
      return;
    }

    this.loadFlows();
  },
  computed: {
    ...mapGetters([
      'hasFeature',
    ]),
  },
  methods: {
    onIframeLoad() {
      this.isLoadingIframe = false;
    },
    reloadFlows() {
      this.loadFlows();
    },
    loadFlows() {
      this.flowsUrl = null;
      this.flowsErrorMessage = null;

      this.commitRequest({
        request: () => {
          const options = {
            url: '/flows/get-embedded-ui-url',
            method: 'POST',
            encoding: null,
          };
          return Api.axios(options);
        },
        onSuccess: (result) => {
          this.flowsUrl = result.flowsUrl;
        },
        onError: (result) => {
          this.flowsErrorMessage = result?.[0]?.error || 'Failed to load flows';
          // don't show the reload button for the multiple regions error because reloading won't help
          this.isErrorReloadButtonShown = result?.[0]?.errorCode !== 'flows_multiple_regions';
        },
      });
    },
  },
};
</script>
