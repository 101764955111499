<script setup>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import { getBuilderNextBaseUrl } from '@/lib/url-helper';
import { ADD_IMPORT_V1 } from '@/constants/featureflags';
import Icon from '@/components/ui/Icon';
import EmptyStateGeneric from '@/components/ui/EmptyStateGeneric';
import BuilderButton from '@/components/ui/BuilderButton';
import { isTrial } from '@/lib/plan-helper';

const router = useRouter();
const store = useStore();

const handleAddTableButtonClick = () => (store.getters.hasFeature(ADD_IMPORT_V1) ? window.location.replace(`${getBuilderNextBaseUrl()}/tables/add`) : router.push('/schema/list/objects/add/import'));
</script>

<template>
  <EmptyStateGeneric top>
    <div class="mb-2">
      <Icon
        type="arrow-right-circle"
        class="tw-rotate-180 h-auto w-[110px] fill-[url(#svg-brand-gradient)] opacity-40"
      />
    </div>

    <h2 class="margin-bottom-lg text-xl text-emphasis font-medium mb-2">
      Create or Update a Table
    </h2>

    <p class="text-default mb-6">
      Select a table to update from the left panel or create a new one
    </p>

    <BuilderButton
      theme="confirm"
      data-feature="add_table_button"
      data-feature-x-offset="-26"
      data-feature-y-offset="-26"
      size="lg"
      class="mb-6"
      @click="handleAddTableButtonClick"
    >
      <icon type="add" /> Add a Table
    </BuilderButton>
  </EmptyStateGeneric>
</template>
