import {
  createRouter,
  createWebHistory,
  isNavigationFailure,
  NavigationFailureType,
} from 'vue-router';
import hasIn from 'lodash/hasIn';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

import { logPage } from '@/lib/segment-helper';
import { getBuilderNextBaseUrl } from '@/lib/url-helper';
import rootRoutes from '@/router/rootRoutes';
import store from '@/store';
import { ADD_IMPORT_V1, INTEGRATIONS } from '@/constants/featureflags';
import { isTrial } from '@/lib/plan-helper';

const splitPathname = window.location.pathname.split('/');
const accountSlug = splitPathname[1];
const applicationSlug = splitPathname[2];

const router = createRouter({
  history: createWebHistory(`/${accountSlug}/${applicationSlug}`),
  routes: rootRoutes,
});

const commitRoute = (routeType, routeValue) => {
  store.commit('routes/updateRoute', {
    routeType,
    routeValue,
  });
};

const commitQuery = (queryType, queryKey, queryValue) => {
  store.commit('routes/updateQuery', {
    queryType,
    queryKey,
    queryValue,
  });
};

/**
 * Locally logs in the user if they have a valid authentication token.
 * If local authentication is false, checks the user token against the server and
 * updates the local authentication if it passed.
 *
 * @returns {Promise<boolean>}
 */
const loginLocallyIfAuthenticated = async () => {
  if (store.getters.isBuilderAuthenticated) {
    return true;
  }

  log('store.getters.isBuilderAuthenticated was false, checking if api wrapper isAuthenticated');

  const isAuthenticated = await window.Knack.Api.isAuthenticated();

  if (isAuthenticated) {
    log('API wrapper is authenticated, ensuring store.isBuilderAuthenticated is set');

    store.commit('authenticateBuilder');
  }

  return Boolean(isAuthenticated);
};

router.afterEach(({ path, params }, from, failure) => {
  // Skip this flow if the navigation failed (including aborts).
  if (failure) {
    return;
  }

  logPage();

  // Route Persistence for Schema, Records, Tasks (srtRoutes)
  // if going to an SRT route..
  // if there's an object being viewed, store the path for all SRT routes
  // otherwise reset all SRT routes to their non-objectKey root path
  const srtRoutes = ['schema', 'records', 'tasks'];
  const isSrtRoute = srtRoutes.some((srtRoute) => path.startsWith(`/${srtRoute}`));
  if (isSrtRoute) {
    if (hasIn(params, 'objectKey')) {
      commitRoute('schema', `/schema/list/objects/${params.objectKey}/fields`);
      commitRoute('records', `/records/objects/${params.objectKey}`);
      commitRoute('tasks', `/tasks/objects/${params.objectKey}`);
    } else {
      srtRoutes.forEach((srtRoute) => commitRoute(srtRoute, ''));
    }
  }

  // Route Persistence for Pages and Settings (psRoutes)
  // if going to a PS route, commit the route path
  const psRoutes = ['pages', 'settings'];
  psRoutes.forEach((psRoute) => {
    if (path.startsWith(`/${psRoute}`)) {
      commitRoute(psRoute, path);
    }
  });
});

/**
 * This afterEach() will make sure that the active page and active view are
 * set on pages that have a pageKey and/or viewKey as a param. It will also
 * clear/unset the activePage and activeView if no params exist.
 */
router.afterEach(async (to, from, failure) => {
  // Skip this flow if the navigation failed (including aborts).
  if (failure) {
    return;
  }

  const { pageKey, viewKey } = to.params;

  // Wait for the next tick before processing these updates.
  // This ensures that components are mounted before we update the active page and active view
  // in order to avoid type errors.
  await new Promise((resolve) => {
    setTimeout(resolve, 0);
  });

  const pathWithIntegrations = ['/settings/integrations', '/schema/list/objects/add/integration'];
  if (pathWithIntegrations.includes(to.path) && !store.getters['integrations/hasLoaded']) {
    store.commit('commitRequest');
    await store.dispatch('integrations/init');
    store.commit('completeRequest');
  }

  const activeViewKey = store.getters.activeView?.key;
  const activePageKey = store.getters.activePage?.key;

  if (!pageKey && activePageKey) {
    log('CLEARING ACTIVE PAGE', activePageKey);

    store.commit('clearActivePage');
  } else if (pageKey !== activePageKey) {
    log('SETTING ACTIVE PAGE TO', pageKey);

    await store.dispatch('setActivePageByKey', { pageKey });
  }

  if (!viewKey && activeViewKey) {
    log('CLEARING ACTIVE VIEW');

    // Clear any active views.
    await store.dispatch('setActiveViewByKey', { viewKey: null });
  } else if (viewKey !== activeViewKey) {
    log('SET ACTIVE VIEw HERE', viewKey);

    await store.dispatch('setActiveViewByKey', { viewKey });
  }
});

router.beforeEach(async (to, from, next) => {
  // Redirect bad routes to parent (eventually, `/`)
  if (isEmpty(to.matched)) {
    const parentRoute = to.fullPath.split('/')
      .slice(0, -1)
      .join('/');

    if (isEmpty(parentRoute)) {
      return next('/');
    }

    return next(parentRoute);
  }

  if (to.path === '/logout') {
    // Skip all the follow router checks for the logout route.
    return next();
  }

  const isUserAuthenticated = await loginLocallyIfAuthenticated();

  // If hitting the non-existent `/login` route, redirect to root
  if (to.path === '/login') {
    return next('/');
  }

  // check if route's requiredAuth meta is false
  if (to.matched.some((record) => record.meta.requiresAuth === false)) {
    return next();
  }

  if (!isUserAuthenticated) {
    log('api wrapper is not authenticated, redirecting to login');

    if (process.env.VUE_APP_DASHBOARD_URL) {
      return window.location.replace(`${process.env.VUE_APP_DASHBOARD_URL}?builderPathname=${encodeURIComponent(window.location.pathname)}`);
    }

    return window.location.replace('/');
  }

  // Prevent users from accessing add table routes if they should be interacting with feature in new builder
  const pathWithAddTable = ['/schema/list/objects/add/import', '/schema/list/objects/add/scratch', '/schema/list/objects/add/template'];
  const isAddUserRolePath = to.fullPath.includes('?user=true');

  const shouldPreventAddTableRoute = pathWithAddTable.includes(to.path)
    && store.getters.hasFeature(ADD_IMPORT_V1)
    && !isAddUserRolePath;

  if (shouldPreventAddTableRoute) {
    // redirect to new builder
    return window.location.replace(`${getBuilderNextBaseUrl()}/tables/add`);
  }

  // Prevent users from accessing import records routes if they should be interacting with feature in new builder
  const shouldPreventRecordsImportRoute = [`/records/objects/${to.params.objectKey}/import`].includes(to.path) && store.getters.hasFeature(ADD_IMPORT_V1);

  if (shouldPreventRecordsImportRoute) {
    // redirect to new builder
    return window.location.replace(`${getBuilderNextBaseUrl()}/tables/${to.params.objectKey}/import`);
  }

  // Prevent users from accessing the integration pages if they don't have the feature flag
  const pathWithIntegrations = ['/settings/integrations', '/settings/integrations/add', '/schema/list/objects/add/integration'];
  const shouldPreventIntegrationsRoute = pathWithIntegrations.includes(to.path)
    && !store.getters.hasFeature(INTEGRATIONS);

  if (shouldPreventIntegrationsRoute) {
    // Redirect to parent
    const parentRoute = to.fullPath.split('/')
      .slice(0, -1)
      .join('/');

    return next(parentRoute);
  }

  // load the application
  if (!store.getters.appIsLoaded) {
    log('The app is not loaded, forwarding to loading page...');

    // Avoid infinite loops.
    if (to.path === '/loading') {
      return next();
    }

    return next({
      replace: true,
      path: '/loading',
      query: {
        redirect: to.fullPath,
      },
    });
  }

  // Check if we need to add queries to the route
  // Currently used in /records to preserve record filters
  if (to.meta.addQueries) {
    // Delete any redirect vars, loading and logins handle these
    delete from.query.redirect;

    if (Object.keys(to.query).length !== Object.keys(from.query).length) {
      const toWithQuery = { ...to, query: from.query };

      return next(toWithQuery);
    }
  }

  // if user is testing SSO from login view settings we want to redirect
  // them back to their specific setting page
  const ssoTestStatus = (window.location.href.split('?')[1] ? window.location.href.split('?')[1].indexOf('state=') > -1 : false);

  if (ssoTestStatus) {
    const code = new URL(window.location.href).searchParams.get('code') ? new URL(window.location.href).searchParams.get('code') : localStorage.getItem('sso-test-code');

    localStorage.setItem('sso-test-code', code);

    const pageKey = localStorage.getItem('sso-test-page-key');
    const viewKey = localStorage.getItem('sso-test-view-key');

    // deeplink back to specific setting
    const strategyName = new URL(window.location.href).searchParams.get('p') ? new URL(window.location.href).searchParams.get('p') : localStorage.getItem('sso-test-strategy-name');
    const strategyType = ([
      'google',
      'openid',
    ].indexOf(strategyName) > -1) ? 'sso' : 'custom_sso';
    const url = `${window.location.href.split('?')[0]}#/pages/${pageKey}/views/${viewKey}/login/settings/${strategyType}/edit/${strategyName}`;

    window.location.assign(url);
  }

  if (localStorage.getItem('sso-test-code')) {
    store.commit('updateSSOTestCode', localStorage.getItem('sso-test-code'));
  }

  // handle active models that populate based on query parameters
  // TODO: move all other active models here?
  if (to.params.objectKey) {
    store.commit('activeObject', store.getters.getObject(to.params.objectKey));
  } else {
    store.commit('activeObject', false);
  }

  // After app has loaded, guard invalid params and redirect to component parent
  const baseComponentRoute = to.fullPath.split('/')
    .slice(0, 2)
    .join('/');

  // These are schema keys that could potentially not exist, along with their functions to verify
  // Null functions don't have a single parameter check in the store
  const paramFunctions = {
    objectKey({ objectKey }) {
      return store.getters.getObject(objectKey);
    },
    fieldKey({ fieldKey }) {
      return store.getters.getField(fieldKey);
    },
    pageKey({ pageKey }) {
      return store.getters.doesPageExist(pageKey);
    },
    viewKey({ viewKey }) {
      return store.getters.doesViewExist(viewKey);
    },
    taskKey({ objectKey, taskKey }) {
      const object = store.getters.getObject(objectKey);

      return object.tasks.find(({ key }) => key === taskKey);
    },
  };
  const routeKeys = Object.keys(to.params);
  const paramKeys = Object.keys(paramFunctions);
  const foundKeys = routeKeys.filter((routeKey) => paramKeys.includes(routeKey)); // intersection

  // If we don't have any found keys this function has no more work to do
  if (isEmpty(foundKeys)) {
    return next();
  }

  // Ensure our found keys still exist in the schema
  for (const paramKey of foundKeys) {
    if (isNil(to.params[paramKey])) {
      continue;
    }

    // Call the store function for this parameter to confirm it exists in the schema
    if (!paramFunctions[paramKey](to.params)) {
      return next(baseComponentRoute);
    }
  }

  return next();
});

// Necessary as of 3.1.1 to avoid console errors on returning router.push that encounters a
// next(false) per: https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = router.push;
router.push = async (to) => {
  const navFailure = await originalPush(to);
  if (!navFailure) {
    return;
  }

  // @see https://next.router.vuejs.org/guide/advanced/navigation-failures.html
  if (isNavigationFailure(navFailure, NavigationFailureType.aborted)) {
    log(`Navigation aborted -> from '${navFailure.from.path}' to '${navFailure.to.path}'.`);
  } else if (isNavigationFailure(navFailure, NavigationFailureType.cancelled)) {
    log(`Navigation cancelled -> from '${navFailure.from.path}' to '${navFailure.to.path}'.`);
  } else if (isNavigationFailure(navFailure, NavigationFailureType.duplicated)) {
    log(`Navigation duplicated -> already at ${navFailure.to.path}.`);
  }
};

const originalReplace = router.replace;
router.replace = async (to) => {
  const navFailure = await originalReplace(to);
  if (!navFailure) {
    return;
  }

  // @see https://next.router.vuejs.org/guide/advanced/navigation-failures.html
  if (isNavigationFailure(navFailure, NavigationFailureType.aborted)) {
    log(`Navigation aborted -> from '${navFailure.from.path}' to '${navFailure.to.path}'.`);
  } else if (isNavigationFailure(navFailure, NavigationFailureType.cancelled)) {
    log(`Navigation cancelled -> from '${navFailure.from.path}' to '${navFailure.to.path}'.`);
  } else if (isNavigationFailure(navFailure, NavigationFailureType.duplicated)) {
    log(`Navigation duplicated -> already at ${navFailure.to.path}.`);
  }
};

export default router;
