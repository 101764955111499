<template>
  <Ribbon id="records-ribbon">
    <AccountsBatchActions
      v-if="showAccountBatchActions"
      :action="accountBatchAction"
      :object-key="object.key"
      :record-ids="checkedRecordIds"
      @close="showAccountBatchActions = false"
    />
    <div
      v-if="tabStyle === 'legacy'"
      class="ribbon_mainRow h-min py-3"
    >
      <h2 class="ribbonName mr-12">
        <Icon
          :type="objectIcon"
          class="ribbonName_icon text-default h-6 w-6"
        />
        <RouterLink
          :to="``"
          class="ribbonName_link truncate text-emphasis text-xl"
          v-html="activeObject.name"
        />
        <ObjectEditDropdown
          base-url="/records/objects"
          :object="object"
        />
      </h2>

      <ObjectTypeLinks
        #content
        :object="object"
        active-type="records"
      />
    </div>

    <ObjectTypeTabs
      v-if="tabStyle === 'connections-split'"
      :object="object"
      active-tab="records"
    />

    <div class="ribbon_subRow border-0 gap-2 py-3 h-auto">
      <div
        v-if="showCheckboxActions"
        class="flexCenter gap-4"
      >
        <div class="margin-right-xxs">
          <input
            class="margin-right-xxs"
            type="checkbox"
            checked
            disabled
          > &nbsp;<b>with checked</b>&nbsp;({{ checkedRecordIds.length }})
        </div>

        <span
          v-if="isIntegrationObject"
          v-tippy
          content="Incompatible with table"
          class="iconLink ribbonLink"
          :class="[buttonClasses, disabledButtonClasses]"
        >
          <Icon
            type="edit"
            class="iconLink_icon ribbonLink_icon text-default"
          />
          <RibbonLinkText text="Update" />
        </span>
        <RouterLink
          v-else
          v-tippy
          content="Update Selected"
          :to="`/records/objects/${objectKey}/batch/update`"
          class="iconLink ribbonLink"
          :class="buttonClasses"
        >
          <Icon
            type="edit"
            class="iconLink_icon ribbonLink_icon text-default"
          />
          <RibbonLinkText text="Update" />
        </RouterLink>
        <button
          v-tippy
          content="Delete Selected"
          class="iconLink ribbonLink"
          :class="buttonClasses"
          @click.prevent="onDeleteSelectedRecordsClick"
        >
          <Icon
            type="delete"
            class="iconLink_icon ribbonLink_icon text-default"
          />
          <RibbonLinkText text="Delete" />
        </button>

        <template v-if="object.isUser()">
          <button
            v-tippy
            content="Update Status"
            class="iconLink ribbonLink"
            :class="buttonClasses"
            @click="triggerAccountBatchActions(`update`)"
          >
            <Icon
              type="user"
              class="iconLink_icon ribbonLink_icon text-default"
            />
            <RibbonLinkText text="Update Status" />
          </button>
          <button
            v-tippy
            content="Email Info"
            class="iconLink ribbonLink"
            :class="buttonClasses"
            @click="triggerAccountBatchActions(`intro`)"
          >
            <Icon
              type="email"
              class="iconLink_icon ribbonLink_icon text-default"
            />
            <RibbonLinkText text="Email Info" />
          </button>
          <button
            v-tippy
            content="Reset Passwords"
            class="iconLink ribbonLink"
            :class="buttonClasses"
            @click="onResetPasswordsClick"
          >
            <Icon
              type="lock"
              class="iconLink_icon ribbonLink_icon text-default"
            />
            <RibbonLinkText text="Reset Passwords" />
          </button>
        </template>
      </div>

      <div
        v-else
        class="flexCenter gap-4"
      >
        <!-- Add record button -->
        <span
          v-if="showAddNewRecords && isIntegrationObject"
          v-tippy
          content="Incompatible with table"
          class="iconLink ribbonLink border border-solid border-default p-3 rounded-lg bg-transparent text-emphasis h-10 m-0 hover:bg-brand-50"
          :class="[disabledButtonClasses]"
        >
          <Icon
            type="plus-thin"
            class="green-text iconLink_icon iconLink_icon-add ribbonLink_icon text-default mr-0 h-4 w-4"
          />
          <RibbonLinkText
            class="text-base font-medium"
            text="Add Record"
          />
        </span>
        <RouterLink
          v-else-if="showAddNewRecords"
          v-tippy
          content="Add a new record"
          data-feature="add_record_button"
          data-feature-x-offset="-26"
          data-feature-y-offset="-26"
          :to="`/records/objects/${objectKey}/add`"
          class="iconLink ribbonLink ribbonLink-persist border border-solid border-default p-3 rounded-lg bg-transparent text-emphasis h-10 m-0 hover:bg-brand-50 hover:border-brand-600 group"
          data-cy="add-record-button"
          style="margin-left: -8px;"
        >
          <Icon
            type="plus-thin"
            class="green-text iconLink_icon iconLink_icon-add ribbonLink_icon text-default mr-0 group-hover:text-brand-400 h-4 w-4"
          />
          <RibbonLinkText
            class="text-base font-medium"
            text="Add Record"
          />
        </RouterLink>

        <!-- Import button -->
        <span
          v-if="showAddNewRecords && !hasActiveJobs && isIntegrationObject"
          v-tippy
          content="Incompatible with table"
          class="iconLink ribbonLink"
          :class="[buttonClasses, disabledButtonClasses]"
        >
          <Icon
            type="cloud-import"
            class="iconLink_icon ribbonLink_icon text-default"
          />
          <RibbonLinkText
            text="Import"
            collapsible
          />
        </span>
        <div v-else-if="showAddNewRecords && !hasActiveJobs">
          <a
            v-if="shouldNavigateToBuilderNext"
            v-tippy
            content="Import records"
            :href="builderNextBaseUrl + `/tables/${objectKey}/import`"
            class="iconLink ribbonLink"
            :class="[buttonClasses]"
            data-cy="import-records"
          >
            <Icon
              type="cloud-import"
              class="iconLink_icon ribbonLink_icon text-default"
            />
            <RibbonLinkText
              text="Import"
              collapsible
            />
          </a>
          <RouterLink
            v-else
            v-tippy
            content="Import records"
            :to="`/records/objects/${objectKey}/import`"
            class="iconLink ribbonLink"
            :class="[buttonClasses]"
            data-cy="import-records"
          >
            <Icon
              type="cloud-import"
              class="iconLink_icon ribbonLink_icon text-default"
            />
            <RibbonLinkText
              text="Import"
              collapsible
            />
          </RouterLink>
        </div>
        <span
          v-else-if="hasActiveJobs"
          v-tippy="{ allowHTML: true }"
          content="Please wait for the current job to complete<br />before starting a new one."
          class="iconLink ribbonLink"
          :class="[buttonClasses, disabledButtonClasses]"
        >
          <Icon
            type="cloud-import"
            class="iconLink_icon ribbonLink_icon text-default"
          />
          <RibbonLinkText
            text="Import"
            collapsible
          />
        </span>

        <!-- Export button -->
        <Popover>
          <template #trigger>
            <button
              v-tippy
              content="Export records"
              type="button"
              class="iconLink ribbonLink"
              :class="[
                buttonClasses,
                !hasRecords ? disabledButtonClasses : '',
              ]"
              :disabled="!hasRecords"
            >
              <Icon
                type="download-export"
                class="iconLink_icon ribbonLink_icon text-default"
              />
              <RibbonLinkText
                text="Export"
                collapsible
              />
              <Icon
                type="arrow-drop-down"
                class="iconLink_icon iconLink_icon-arrowDown text-default"
              />
            </button>
          </template>
          <template #content>
            <div class="links">
              <a
                v-close-popper
                :class="linkItemClasses"
                :href="`${exportUrl}csv`"
              >
                CSV
              </a>
              <a
                v-close-popper
                :class="linkItemClasses"
                :href="`${exportUrl}json`"
              >
                JSON
              </a>
              <a
                v-close-popper
                :class="linkItemClasses"
                :href="`${exportUrl}text`"
              >
                Text
              </a>
            </div>
          </template>
        </Popover>

        <!-- Update button -->
        <span
          v-if="!hasRecords || isIntegrationObject"
          v-tippy
          :content="isIntegrationObject ? 'Incompatible with table' : 'Update records'"
          class="iconLink"
          :class="[buttonClasses, disabledButtonClasses]"
        >
          <Icon
            type="pencil-square"
            class="iconLink_icon ribbonLink_icon text-default"
          />
          <RibbonLinkText
            text="Update"
            collapsible
          />
        </span>
        <RouterLink
          v-else-if="showUpdateRecords && !hasActiveJobs"
          v-tippy
          content="Update records"
          :to="`/records/objects/${objectKey}/batch/update`"
          class="iconLink ribbonLink"
          :class="[buttonClasses]"
          data-cy="batch-update-button"
        >
          <Icon
            type="pencil-square"
            class="iconLink_icon ribbonLink_icon text-default"
          />
          <RibbonLinkText
            text="Update"
            collapsible
          />
        </RouterLink>
        <span
          v-else-if="hasActiveJobs"
          v-tippy="{ allowHTML: true }"
          content="Please wait for the current job to complete<br />before starting a new one."
          class="iconLink"
          :class="[buttonClasses, disabledButtonClasses]"
        >
          <Icon
            type="pencil-square"
            class="iconLink_icon ribbonLink_icon text-default"
          />
          <RibbonLinkText
            text="Update"
            collapsible
          />
        </span>

        <!-- Delete button -->
        <Popover v-if="!hasActiveJobs">
          <template #trigger>
            <button
              v-tippy
              content="Delete"
              type="button"
              class="iconLink ribbonLink"
              :class="[
                buttonClasses,
                !hasRecords ? disabledButtonClasses : '',
              ]"
              :disabled="!hasRecords"
              data-cy="title-buttons-delete-link"
            >
              <Icon
                type="delete"
                class="iconLink_icon ribbonLink_icon text-default"
              />
              <RibbonLinkText
                text="Delete"
                collapsible
              />
              <Icon
                type="arrow-drop-down"
                class="iconLink_icon iconLink_icon-arrowDown text-default"
              />
            </button>
          </template>
          <template #content>
            <div class="links">
              <RouterLink
                v-close-popper
                :class="linkItemClasses"
                :to="`/records/objects/${objectKey}/batch/delete`"
                data-cy="delete-batch-delete-link"
              >
                Batch Delete
              </RouterLink>
              <RouterLink
                v-close-popper
                :class="linkItemClasses"
                :to="`/records/objects/${objectKey}/clear`"
                data-cy="delete-clear-all-records-link"
              >
                Delete All Records
              </RouterLink>
            </div>
          </template>
        </Popover>
        <span
          v-else
          v-tippy="{ allowHTML: true }"
          content="Please wait for the current job to complete<br />before starting a new one."
          class="iconLink ribbonLink"
          :class="[buttonClasses, disabledButtonClasses]"
        >
          <Icon
            type="delete"
            class="iconLink_icon ribbonLink_icon text-default"
          />
          <RibbonLinkText
            text="Delete"
            collapsible
          />
          <Icon
            type="arrow-drop-down"
            class="iconLink_icon iconLink_icon-arrowDown text-default"
          />
        </span>

        <!-- Refresh integration data button -->
        <button
          v-if="isIntegrationObject"
          v-tippy="{ allowHTML: true }"
          type="button"
          data-cy="integration-refresh-button"
          :disabled="!hasRecords || isIntegrationImportInProgress"
          :content="integrationRefreshButtonTooltipContent"
          :class="[
            buttonClasses,
            !hasRecords || isIntegrationImportInProgress ? disabledButtonClasses : '',
            integrationRefreshButtonClasses,
          ]"
          @click="handleIntegrationDataRefresh"
        >
          <Icon
            type="update"
            class="iconLink_icon ribbonLink_icon text-default"
          />
          <RibbonLinkText
            text="Refresh QuickBooks Data"
            collapsible
          />
        </button>
      </div>
    </div>

    <ConfirmModal
      v-if="showDeleteSelectedRecordsConfirmModal"
      title="Delete the selected records?"
      confirm-type="alert"
      @confirm="onConfirmDeleteSelectedRecords"
      @close="hideDeleteSelectedRecordsConfirmModal"
    >
      <p>
        This will permanently delete the selected records <strong>everywhere</strong> they are used in the app.
      </p>
      <p class="mb-0">
        <strong>This can't be undone!</strong>
      </p>
    </ConfirmModal>
  </Ribbon>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import {
  mapGetters,
  mapMutations,
} from 'vuex';

import AccountsBatchActions from '@/components/records/AccountsBatchActions';
import Ribbon from '@/components/layout/Ribbon';
import Icon from '@/components/ui/Icon';
import ObjectTypeLinks from '@/components/objects/ObjectTypeLinks';
import ObjectEditDropdown from '@/components/objects/ObjectEditDropdown';
import Popover from '@/components/ui/Popover';
import RibbonDivider from '@/components/layout/RibbonDivider';
import RibbonLinkText from '@/components/layout/RibbonLinkText';
import ConfirmModal from '@/components/ui/ConfirmModal';
import RequestUtils from '@/components/util/RequestUtils';
import FieldUtils from '@/components/fields/FieldUtils';
import RouteUtils from '@/components/util/RouteUtils';

import Api from '@/lib/api-wrapper';
import { eventBus } from '@/store/bus';
import { getBuilderNextBaseUrl } from '@/lib/url-helper';
import { AB_TESTING_OBJECT_TABS, ADD_IMPORT_V1 } from '@/constants/featureflags';
import { isTrial } from '@/lib/plan-helper';
import ObjectTypeTabs from '@/components/objects/ObjectTypeTabs.vue';

export default {
  name: 'RecordsRibbon',
  components: {
    ObjectTypeTabs,
    AccountsBatchActions,
    Ribbon,
    Icon,
    Popover,
    ObjectTypeLinks,
    ObjectEditDropdown,
    RibbonDivider,
    RibbonLinkText,
    ConfirmModal,
  },
  mixins: [
    RequestUtils,
    FieldUtils,
    RouteUtils,
  ],
  props: {
    hasRecords: {
      type: Boolean,
      default: false,
    },
    layout: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showAccountBatchActions: false,
      accountBatchAction: '',
      showDeleteSelectedRecordsConfirmModal: false,
    };
  },
  computed: {
    ...mapGetters('notifications', [
      'activeSocketNotification',
    ]),
    ...mapGetters([
      'getObject',
      'checkedRecordIds',
      'hasFeature',
      'getFeatureValue',
    ]),
    ...mapGetters('notifications', [
      'socketNotifications',
    ]),
    ...mapGetters('integrations', [
      'isIntegrationImportInProgress',
    ]),
    app() {
      return this.$store.state.app;
    },
    objectKey() {
      return this.$route.params.objectKey;
    },
    activeObject() {
      return this.getObject(this.objectKey);
    },
    showAddNewRecords() {
      return !this.activeObject.ecommerce && !this.activeObject.ecommercePaymentMethods;
    },
    showUpdateRecords() {
      return !this.activeObject.ecommercePaymentMethods;
    },
    showCheckboxActions() {
      return !isEmpty(this.checkedRecordIds);
    },
    exportUrl() {
      // Grab the query params from the route and format for our export api request
      const queryParams = [];

      // Used to swap query keys for what the export API expects
      const querySwaps = {
        builder_table_filters: 'filters',
        builder_table_search: 'search',
      };

      // eslint-disable-next-line array-element-newline, array-bracket-newline, prefer-const
      for (let [key, value] of Object.entries(this.$route.query)) {
        if (!querySwaps[key]) {
          continue;
        }

        // Swap any query keys that the export API expects
        key = querySwaps[key] || key;

        queryParams.push(`${key}=${encodeURIComponent(value)}`);
      }

      // Add custom sorts
      if (this.$route.query.builder_table_sort) {
        const [
          sortField,
          sortOrder,
        ] = this.$route.query.builder_table_sort.split('|');

        queryParams.push(`sort_field=${sortField}`, `sort_order=${sortOrder}`);
      }

      const exportUrlBase = `${Api.api_uri}objects/${this.objectKey}/records/export/applications/${this.app.attributes.id}`;

      if (isNil(queryParams)) {
        return `${exportUrlBase}?type=`;
      }

      return `${exportUrlBase}?${queryParams.join('&')}&type=`;
    },
    hasActiveJobs() {
      return !this.importCompleted && this.activeObject.hasActiveJobs();
    },
    importCompleted() {
      if (this.activeSocketNotification?.jobs) {
        return this.activeSocketNotification.jobs[this.objectKey] === undefined;
      }
      return true;
    },
    objectIcon() {
      return this.activeObject?.get('integration')?.toLowerCase() ?? 'table-cells';
    },
    isIntegrationObject() {
      return !!this.activeObject?.get('integration');
    },
    integrationRefreshButtonClasses() {
      return [
        'iconLink',
        'ribbonLink',
        {
          'is-disabled': !this.hasRecords || this.isIntegrationImportInProgress,
        },
      ];
    },
    integrationRefreshButtonTooltipContent() {
      if (this.isIntegrationObject) {
        if (this.isIntegrationImportInProgress) {
          return 'Please wait for the current QuickBooks data refresh to complete<br />before starting a new one.';
        }
        return 'Refresh Quickbooks Data';
      }

      return this.hasActiveJobs ? 'Please wait for the current job to complete<br />before starting a new one.' : '';
    },
    buttonClasses() {
      return 'border-0 p-3 rounded-lg bg-transparent text-emphasis h-10 m-0 hover:bg-brand-50';
    },
    disabledButtonClasses() {
      return 'is-disabled text-subtle hover:bg-transparent';
    },
    linkItemClasses() {
      return 'rounded hover:bg-brand-50 text-base text-default hover:text-emphasis';
    },
    builderNextBaseUrl() {
      return getBuilderNextBaseUrl();
    },
    shouldNavigateToBuilderNext() {
      return this.hasFeature(ADD_IMPORT_V1);
    },
    tabStyle() {
      return this.getFeatureValue(AB_TESTING_OBJECT_TABS);
    },
  },
  watch: {
    socketNotifications: {
      handler(newValue) {
        if (!this.isIntegrationObject || this.isIntegrationImportInProgress) return;

        const isIntegrationImportJobInProgress = newValue.some((notification) => notification.jobType === 'import' && notification.category === 'progress' && !!this.getObject(notification.object_key)?.get('integration'));
        if (isIntegrationImportJobInProgress) {
          this.$store.dispatch('integrations/updateIsIntegrationImportInProgress', { isIntegrationImportInProgress: true });
        }
      },
    },
  },
  methods: {
    ...mapMutations([
      'setCheckedRecordIds',
    ]),
    onConfirmDeleteSelectedRecords() {
      this.hideDeleteSelectedRecordsConfirmModal();

      eventBus.$emit('beforeRecordsDelete', this.checkedRecordIds);

      this.commitRequest({
        request: () => Api.deleteBatchRecordsById(this.activeObject.key, this.checkedRecordIds),
        onSuccess: () => {
          eventBus.$emit('recordsDelete', this.checkedRecordIds);
          this.$store.dispatch('notifications/jobQueued', {
            jobType: 'delete',
            objectKey: this.activeObject.key,
          });

          this.setCheckedRecordIds([]);
        },
      });
    },
    onDeleteSelectedRecordsClick() {
      this.showDeleteSelectedRecordsConfirmModal = true;
    },
    hideDeleteSelectedRecordsConfirmModal() {
      this.showDeleteSelectedRecordsConfirmModal = false;
    },
    triggerAccountBatchActions(action) {
      this.accountBatchAction = action;
      this.showAccountBatchActions = true;
    },
    onResetPasswordsClick() {
      let action = 'reset';

      // Needed to render an alert modal if no login pages are currently present
      if (!this.$store.getters.doesLoginPageExist) {
        action = 'alert';
      }

      this.triggerAccountBatchActions(action);
    },
    onUpdateAccountStatuses() {
      this.showAccountBatchActions = false;
    },
    handleIntegrationDataRefresh() {
      if (this.hasActiveJobs) return;

      this.commitRequest({
        request: () => this.$store.dispatch('integrations/refetchData'),
        onSuccess: () => {
          this.$notify({
            group: 'top-right',
            duration: 8000,
            'animation-type': 'velocity',
            type: 'success',
            title: 'Data Refresh Started',
            text: 'There may be a delay in seeing your latest data.',
            data: {
              titleIcon: 'check',
            },
          });
          this.$store.dispatch('integrations/updateIsIntegrationImportInProgress', { isIntegrationImportInProgress: true });
        },
        onError: (error) => {
          this.$notify({
            group: 'top-right',
            duration: 8000,
            'animation-type': 'velocity',
            type: 'error',
            title: 'Error',
            text: error?.message ?? 'An error occurred refreshing your data. Please re-authenticate by visiting the integrations tab under settings and connecting again.',
            data: {
              titleIcon: 'error',
            },
          });
        },
      });
    },
  },
};
</script>
